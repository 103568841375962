<template>
  <div>
    <Transition name="fade" mode="out-in">
      <ChannelHeroBanner
        v-if="transitionState == 'banner'"
        :channels="channels"
        :active-channel="activeChannel"
        :active-program="activeProgram"
        :epg-config="epgConfig"
        @on-banner-click="handlePlay"
      ></ChannelHeroBanner>
      <ChannelHeroPlayer
        v-else
        :key="activeChannel?.externalChannelId"
        :channels="channels"
        :active-channel="activeChannel"
        :active-program="activeProgram"
        :epg-config="epgConfig"
        @on-channel-change="(c) => $emit('onChannelChange', c)"
        @on-error="transitionState = 'banner'"
      ></ChannelHeroPlayer>
    </Transition>
  </div>
</template>

<script setup>
const props = defineProps({
  channels: {
    type: Array,
    required: true,
  },
  activeChannel: {
    type: Object,
    required: true,
  },
  activeProgram: {
    type: Object,
    required: true,
  },
  epgConfig: {
    type: Object,
    required: true,
  },
});
const route = useRoute();
const isKidsMode = useIsKidsMode();

const shouldAutoPlay = JSON.parse(route?.query?.autoplay ?? false);
const transitionState = ref("banner");

onMounted(() => {
  if (process.client) {
    if (shouldAutoPlay && navigator.userActivation.hasBeenActive) {
      transitionState.value = "player";
    }
  }
});

const { isPlusNeedPopup } = useNeedLoginPopup();
const handlePlay = async () => {
  useRadioWidget().dismiss();
  if (!isPlusNeedPopup(props.activeChannel)) {
    if (isKidsMode.value) {
      transitionState.value = "player";
      await navigateTo({
        // path: useLocalePath()(`/live/${props.activeChannel.externalChannelId}`),
        replace: true,
        query: {
          autoplay: true,
          channel: props.activeChannel.externalChannelId,
        },
      });
    } else {
      transitionState.value = "player";
      await navigateTo({
        path: useLocalePath()(`/live/${props.activeChannel.externalChannelId}`),
        replace: true,
        query: { autoplay: true },
      });
    }
  }
};
</script>

<script>
export default { name: "ChannelHero" };
</script>

<style lang="scss" scoped></style>
