<template>
  <div class="h-full">
    <div
      :style="{
        backgroundImage: `url('${activeChannel?.images?.banner}')`,
      }"
      class="relative w-full aspect-video lg:h-screen bg-center bg-no-repeat bg-cover"
    >
      <div
        :class="{ '!horizontal-rtl': locale == 'ar' }"
        class="absolute z-0 top-0 start-0 w-full h-full gradient horizontal"
      ></div>
      <div
        class="absolute z-0 bottom-0 start-0 w-full h-1/2 gradient vertical"
      ></div>
      <div class="banner__overlay absolute top-0 start-0 w-full h-full">
        <div
          class="overlay__grid w-full h-full grid-container area-banner content-center auto-rows-min px-4 md:px-10 lg:px-28 gap-2 md:gap-4 lg:gap-6"
        >
          <div id="logo" class="flex items-end">
            <div
              class="text-headline-small md:text-display-small lg:text-display-medium text-white opacity-[0.87]"
            >
              {{ activeProgram.name }}
            </div>
          </div>
          <div id="assetInfo">
            <div class="flex flex-col gap-y-4">
              <div class="flex gap-x-2 items-center">
                <UiTagLive
                  :size="isXs ? 'small' : 'big'"
                  v-if="useIsLive(activeProgram)"
                ></UiTagLive>
                <div class="flex flex-wrap items-center gap-x-1 break-words">
                  <span
                    class="text-body-small lg:text-label-medium text-white/80"
                  >
                    {{ activeProgram.category }}
                  </span>
                  <div v-if="showSeparator" class="px-1 text-white opacity-50">
                    |
                  </div>
                  <div class="flex flex-wrap items-center gap-x-1 break-words">
                    <span
                      :key="key"
                      v-for="(g, key) in activeProgram.genres"
                      class="text-body-small lg:text-body-medium text-white"
                    >
                      {{ g.name }}
                      <Icon
                        v-if="key < activeProgram.genres.length - 1"
                        name="IconDot"
                        class="fill-white"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <p
                v-if="!isXs && activeProgram?.description"
                class="md:text-body-medium lg:text-body-big tracking-wider text-white opacity-[0.87] line-clamp-3"
              >
                {{ activeProgram.description }}
              </p>
            </div>
          </div>
          <div id="cta">
            <ButtonsCta
              class="variant-primary h-9 lg:h-12"
              @click="emit('onBannerClick')"
            >
              <div class="flex gap-x-2 items-center">
                <Icon name="IconPlay" :size="isXs ? 20 : 32"></Icon>
                <span class="text-button-medium lg:text-button-big">
                  {{ $t("Watch Now") }}
                </span>
              </div>
            </ButtonsCta>
          </div>
        </div>
        <div
          v-if="channels.length > 1"
          class="hidden lg:block absolute top-0 end-0 h-full pt-[calc(5rem)]"
        >
          <ChannelHeroChannelListVertical
            v-if="epgConfig"
            :channels="channels"
            :path="epgConfig.basePath"
            :epgPath="epgConfig.epgPath"
            :emit-channel-change="epgConfig.emitChannelChange"
            @on-channel-change="(c) => $emit('onChannelChange', c)"
          >
            <template #epg>
              <NuxtLink :to="localePath(epgConfig.epgPath)">
                <ButtonsCta
                  class="w-full variant-secondary text-button-medium py-2"
                  custom-class
                >
                  {{ $t(epgConfig.label) }}
                </ButtonsCta>
              </NuxtLink>
            </template>
          </ChannelHeroChannelListVertical>
        </div>
      </div>
    </div>
    <div
      v-if="channels.length > 1"
      id="channelListHori"
      class="block lg:hidden w-screen"
    >
      <ChannelHeroChannelListHorizontal
        v-if="epgConfig"
        :channels="channels"
        :path="epgConfig.basePath"
        :epgPath="epgConfig.epgPath"
        :emit-channel-change="epgConfig.emitChannelChange"
        @on-channel-change="(c) => $emit('onChannelChange', c)"
      >
        <template #epg>
          <!-- <ButtonsCta
            class="variant-secondary flex-shrink-0 !text-button-small me-4 md:me-10 md:!px-0 md:w-[117px] md:!text-button-medium"
            @click="onGuideClick"
          >
            <span v-if="isXs">{{ $t("EPG") }}</span>
            <span v-else> {{ $t("Schedule") }}</span>
          </ButtonsCta> -->
          <div class="grid justify-items-end pr-4">
            <NuxtLink class="inline-flex" :to="localePath(epgConfig.epgPath)">
              <ButtonsCta
                class="w-full variant-secondary !text-button-small md:w-[117px] md:!text-button-medium py-2 px-4"
                custom-class
              >
                {{ $t(epgConfig.label) }}
              </ButtonsCta>
            </NuxtLink>
          </div>
        </template>
      </ChannelHeroChannelListHorizontal>
    </div>
  </div>
</template>

<script setup>
const localePath = useLocalePath();
const props = defineProps({
  activeChannel: {
    type: Object,
    required: true,
  },
  activeProgram: {
    type: Object,
    required: true,
  },
  channels: {
    type: Array,
    required: true,
  },
  epgConfig: {
    type: Object,
    required: true,
  },
});

const { locale } = useI18n();
const emit = defineEmits("onBannerClick");

const { isXs } = useBreakpoints();

const onGuideClick = () => {
  const isKidsMode = useIsKidsMode();
  const guideTitle = useCookie("guide_title");
  guideTitle.value = "Schedule";

  const {
    epgPath,
    epgQuery: { external_category_id },
  } = props.epgConfig;
  const path = `${epgPath}/${external_category_id}`;
  navigateTo({
    path: localePath(isKidsMode.value ? `/kids${path}` : path),
  });
};
</script>

<style lang="scss" scoped></style>
